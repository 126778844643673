import axiosInstance from '../axiosInstance';
import { AxiosError } from 'axios';

import { setIsLoading } from 'app/slices/appSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';

import config from 'config/config.json';

import {
  getFakeEmailUrl,
  getProfileCompletionUrl,
  getSecretQuestionsPostUrl,
  getSecretQuestionsUpdateUrl,
  getSecretQuestionsUrl,
  getUserInfoUrl,
  getUserUrl,
  getContactNoCheck,
  getEmailCheck,
  getDocumentDataUrl,
} from './UserApiService';

type DispatchType = ReturnType<typeof useAppDispatch>;

export async function getUser(
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) {
  const url = getUserUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const { data } = await instance.get(url, {
    headers: {
      RequestId: requestId,
    },
  });

  return data;
}

// TODO type body properly
export const putUserInfo = async (
  body: any,
  requestId: string,
  isIdVerified: boolean = false,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) => {
  const url = getUserInfoUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.put(url, body, {
    headers: {
      RequestId: requestId,
      IsDocumentVerification: `${isIdVerified}`,
    },
  });
  return response;
};

export const getSecretQuestions = async (
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) => {
  const url = getSecretQuestionsUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.get(url, {
    headers: {
      RequestId: requestId,
      SessionId: sessionToken,
    },
  });
  return response;
};

// TODO type body properly
export const postSecretQuestions = async (
  body: any,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) => {
  const url = getSecretQuestionsPostUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.post(url, body, {
    headers: {
      RequestId: requestId,
    },
  });
  return response;
};

// TODO type body properly
export const updateSecretQuestions = async (
  body: any,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) => {
  const url = getSecretQuestionsUpdateUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = await instance.put(url, body, {
    headers: {
      RequestId: requestId,
    },
  });

  return response;
};

export const getProfileCompletion = async (
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) => {
  const url = getProfileCompletionUrl();
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const response = instance.get(url, {
    headers: {
      RequestId: requestId,
    },
  });
  return response;
};

export async function isFakeEmail(
  email: string,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) {
  if (config.VALIDATE_EMAIL) {
    try {
      const url = getFakeEmailUrl();
      const instance = axiosInstance(localStorage, dispatch, sessionToken);
      const { data } = await instance.get(url, {
        headers: {
          RequestId: requestId,
        },
        params: {
          emailId: email,
        },
      });
      return {
        email_status: data,
        isFake: data === 0,
      };
    } catch (error) {
      console.error('Email validation error: ', error);

      return {
        email_status: 0,
        isFake: true,
      };
    }
  } else return false;
}

export async function checkContactExists(
  phone: string,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) {
  try {
    const url = getContactNoCheck();
    const instance = axiosInstance(localStorage, dispatch, sessionToken);
    const response = await instance.get(url, {
      headers: {
        RequestId: requestId,
      },
      params: {
        contactno: phone,
      },
    });
    return response;
  } catch (error) {
    console.error('Phone number validation error: ', error);
    return null;
  }
}

export async function checkEmailExists(
  email: string,
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  sessionToken: string,
) {
  try {
    const url = getEmailCheck();
    const instance = axiosInstance(localStorage, dispatch, sessionToken);
    const response = await instance.get(url, {
      headers: {
        RequestId: requestId,
      },
      params: {
        email,
      },
    });
    return response;
  } catch (error) {
    console.error('Email validation error: ', error);
    return null;
  }
}

export async function getDocumentData(
  requestId: string,
  localStorage: Storage,
  dispatch: DispatchType,
  imagesBase64: string[],
  sessionToken: string,
) {
  const instance = axiosInstance(localStorage, dispatch, sessionToken);
  const url = getDocumentDataUrl();
  const body = JSON.stringify({
    images: imagesBase64,
  });
  try {
    const { data } = await instance.post(url, body, {
      headers: {
        Handle400Locally: 'true',
        'Content-Type': 'application/json',
        RequestId: requestId,
      },
    });
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 400) {
      dispatch(setIsLoading(false));
      return axiosError.response.data;
    }
    // If it's any other error, re-throw it so it's caught by the global errorHandler
    return error;
  }
}
